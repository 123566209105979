import React from "react";
import { Route, BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import Loader from "react-loader-spinner";

const PendingComponent = React.lazy(() => import("./components/PendingsComponent/PendingComponent"))
const SettlementComponent = React.lazy(() => import("./components/SettelementComponent/SettlementComponent"))
const Requests = React.lazy(() => import("./components/RequestComponent/Requests"))
const UserLibrary = React.lazy(() => import("./components/DocumentLibraryComponent/DocumentLibrary"))
const UserCommonBackground = React.lazy(() => import("./components/UserCommonBackground/UserCommonBackground"))
const Home = React.lazy(() => import("./HomeComponent/Home"))
const BankDetails = React.lazy(() => import("./pages/BankDetails/BankDetails"))
const BuyerPlatformSetup = React.lazy(() => import("./pages/BuyerPlatformSetup/BuyerPlatformSetup"))
const CompanyDetails = React.lazy(() => import("./pages/CompanyDetails/CompanyDetails"))
const CreateRequest = React.lazy(() => import("./pages/CreateRequest/CreateRequest"))
const EditAccount = React.lazy(() => import("./pages/EditAccount/EditAccount"))
const UserProfile = React.lazy(() => import("./pages/UserProfile/UserProfile"))
const Document = React.lazy(() => import("./pages/Document/Document"))
const Remittance = React.lazy(() => import("./components/viewRemittance/viewRemittance"))
const InvoiceVerify = React.lazy(() => import("./pages/InvoiceVerify/InvoiceVerify"));
const Monitor = React.lazy(() => import("./pages/Monitor/Monitor"));


const ParentContainerComponent = ({ socket }) => {
  let id = window.localStorage.getItem("_id");
  if (window.localStorage.getItem("_id")) {
    id = window.localStorage.getItem("_id");
  }
  return (

    <div className="App" >
      <Router>
        <React.Suspense fallback={<p>Loading...</p>}>
          <Switch>
            <Route exact path="/invoiceverify" render={() => <InvoiceVerify />} />
            <Route exact path="/monitor/:id/:token" component={Monitor} />
            {
              id != null ?
                ["/user", "/"].map(path => (
                  <Route
                    key={path}
                    exact
                    path={path}
                    render={() => <Home id={id} socket={socket} />}
                  />
                ))
                :
                [
                  "/user",
                  "/user/createAccount/:extraData?",
                  "/:extraData?"
                ].map(path => (
                  <Route
                    key={path}
                    exact
                    path={path}
                    render={() => <UserCommonBackground />}
                  />
                ))
            }
            <Route exact path="/user/tnc" component={UserCommonBackground} />
            <Route exact path="/user/SelectBuyerPlatform" component={UserCommonBackground} />
            <Route exact path="/user/pendings" component={props => <PendingComponent socket={socket} />} />
            <Route exact path="/user/settlements" component={props => <SettlementComponent socket={socket} />} />
            <Route exact path="/user/requests" component={props => <Requests socket={socket} />} />
            <Route exact path="/user/document/library" component={props => <UserLibrary socket={socket} />} />
            <Route exact path="/user/bankDetails" component={BankDetails} />
            <Route exact path="/user/setupbuyerplatform/:selectedBP" component={BuyerPlatformSetup} />
            <Route exact path="/user/companyDetails" component={props => <CompanyDetails />} />
            <Route exact path="/user/createRequest" component={props => <CreateRequest socket={socket} />} />
            <Route exact path="/user/documents" component={Document} />
            <Route exact path="/userProfile" component={UserProfile} />
            <Route exact path="/userProfile/account" component={EditAccount} />
            <Route exact path="/userProfile/companyDetail" component={CompanyDetails} />
            <Route exact path="/userProfile/bankDetail" component={BankDetails} />
            <Route exact path="/userProfile/documents" component={Document} />
            <Route exact path="/userProfile/setUpBuyerPlatform/:selectedBP" component={BuyerPlatformSetup} />
            <Route exact path="/user/Remittance" component={props => <Remittance socket={socket} />} />
            {/* <Route path='*' exact component={Home} /> */}
            <Redirect to="/user" />
          </Switch>
        </React.Suspense>
      </Router>
    </div>
  )
}


export default ParentContainerComponent;