import t from "../actions/FilterActions/types";
import moment from "moment";
import DateFormatter from '../services/DateFormatter';

const initialState = {
    filterDataResponse: [],
    totalPages: -1,
    totalElement: 0,
    searchData: {
        "invoices": {},
        "payments": {
            "merchantBuyerPlatformsId": {},
            "paymentType": "Settlement"
        },
        "PurchaseOrder": {},
        "pending": {
            "merchantBuyerPlatformsId": {}
        },
        "wallet": {
            "merchantBuyerPlatformsId": {}
        },
        "traditionalrequests": {
            "merchantBuyerPlatformsId": {}
        }
    }
}

export default (state = initialState, action) => {
    switch (action.type) {

        case t.GET_FILTER_DATA:

            if (action.filterFor === "payments") {
                return {
                    ...state,
                    filterDataResponse: action.payload ? action.payload.payments.content : state.filterDataResponse,
                    totalPages: action.payload?.payments?.totalPages ? action.payload?.payments?.totalPages : 0,
                }
            }
            if (action.filterFor == "invoices") {
                //Net Payments
                let invoicesData = action.payload ? action.payload.content : [];
                const df = new DateFormatter();
                let tmpData = {};
                invoicesData.forEach((element) => {
                    (tmpData[df.dateformatter(element.dueDate)] = tmpData[df.dateformatter(element.dueDate)] || []).push(element);
                });

                let tmpDates = Object.keys(tmpData);
                tmpDates.sort(function (a, b) {
                    return moment(a, 'DD MMM YY').format('X') - moment(b, 'DD MMM YY').format('X');
                })

                return {
                    ...state,
                    filterDataResponse: action.payload ? action.payload?.content : state.filterDataResponse,
                    totalPages: action.payload ? action.payload?.totalPages : 0,
                    netPayment: tmpDates,
                    dateWiseInvoices: tmpData,
                }

            }
            if (action.filterFor === "wallet") {
                return {
                    ...state,
                    filterDataResponse: action.payload ? action.payload?.content : state.filterDataResponse,
                    totalPages: action.payload ? action.payload?.totalPages : 0,
                }
            }
            if (action.filterFor === "traditionalrequests") {
                return {
                    ...state,
                    filterDataResponse: action.payload ? action.payload?.traditionalrequests.content : state.filterDataResponse,
                    totalPages: action.payload ? action.payload?.traditionalrequests.totalPages : 0,
                    totalElements: action.payload ? action.payload?.traditionalrequests.totalElements : 0,
                }
            }
            if (action.filterFor === "payments") {
                return {
                    ...state,
                    filterDataResponse: action.payload ? action.payload?.payments.content : state.filterDataResponse,
                    totalPages: action.payload ? action.payload?.payments.totalPages : 0,
                    totalElements: action.payload ? action.payload?.payments.totalElements : 0,
                }
            }
            if (action.filterFor === "pending") {
                return {
                    ...state,
                    filterDataResponse: action.payload ? action.payload?.pending.content : state.filterDataResponse,
                    totalPages: action.payload ? action.payload?.pending.totalPages : 0,
                    totalElements: action.payload ? action.payload?.pending.totalElements : 0,
                }
            }
            return {
                ...state
            }
            break;
        case t.UN_PAID_INVOICES_LIST:
            console.log("action.payload", action.payload);
            return {
                ...state
            }
            break;
        default:
            return {
                ...state
            }
            break;
    }

}