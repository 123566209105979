import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as registerServiceWorker from "./registerServiceWorker";
import { Provider } from "react-redux";
import { store } from './redux_store';
import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';



ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <ClearBrowserCacheBoundary auto fallback='Loading' duration={60000}>
        <App />
      </ClearBrowserCacheBoundary>
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker.unregister();
