import React, { Component } from "react";
import "./App.css";
import "typeface-roboto";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import ParentContainerComponent from "./ParentContainerComponent";
import { socketUrl } from './constants'
import * as socketIoClient from "socket.io-client";

class App extends Component {
  state = {
    socket: {}
  }
  id = window.localStorage.getItem("_id");
  componentDidMount() {
    this.setState({
      socket: socketIoClient(socketUrl, { query: 'u_id=' + this.id })
    })
  }

  constructor(props) {
    super(props);
    this.state = { addClass: false }
  }
  toggle() {
    this.setState({ addClass: !this.state.addClass });
  }

  render() {
    let buttonClass = (this.state.toggled) ? 'toggled' : '';
    return (
      <div className="App_new">
        <div id="sidebarWrap" className={buttonClass}>
          <ParentContainerComponent socket={this.state.socket} />
        </div>
      </div>
    )
  }
}

export default App;
