import t from "../actions/MerchantActions/types";
import moment from 'moment'
const initialState = {
  merchantList: {},
  dashboardData: {
    dashboard: {},
  },
  createRequestData: {},
  getRemittance: {
    payments: []
  },
  getRemittanceByMonth: [],
  getNotifications: [],
  verifyEmailData: {},
  verifyOtpData: {},
  changePswdData: {},
  cmsPageSingle: undefined,
  cmsPage: undefined,
  subMerchant: [],
  documentList: [],
  documentViewFile: {},
  userDetails: {},
  notificationList: [],
  totalElement: 0
};

export default (state = initialState, action) => {
  switch (action.type) {
    case t.FETCHED_ACTIVITY:
      //console.log("Reducer Data", action);
      return {
        ...state,
        merchantList: action.payload,
      };

    case t.FETCHED_DASHBOARD_ACTIVITY:
      return {
        ...state,
        dashboardData: action.payload.body
      }
    case t.CREATE_REQUEST:
      return {
        ...state,
        createRequestData: action.payload
      }
    case t.GET_REMITTANCE:
      //console.log("GET_REMITTANCE", Object.assign({}, state.getRemittance, action.payload.body));

      if (action.payload.body) {
        sortedData = action.payload.body?.payments?.content?.sort((a, b) => {
          return b.paymentsDate - a.paymentsDate;
        });
      }
      return {
        ...state,
        getRemittance: action.payload.body ? sortedData : []
      }

    case t.GET_REMITTANCE_BY_MONTH:
      let tmpData = [];
      if (action.payload.body) {
        action.payload.body.payments.forEach((element) => {
          (tmpData[moment(element.paymentsDate).format("MMM")] = tmpData[moment(element.paymentsDate).format("MMM")] || []).push(element);
        })
      }

      return {
        ...state,
        getRemittanceByMonth: tmpData
      }
    case t.GET_NOTIFICATIONS:
      var sortedData = [];
      if (action.payload.body) {
        sortedData = action.payload.body.notifications.sort((a, b) => {
          return b.creationTimestamp - a.creationTimestamp;
        });
      }

      return {
        ...state,
        getNotifications: action.payload.body ? sortedData : []
      }
    case t.UPDATE_NOTIFICATIONS:
      return {
        ...state,
        updateNotificationsData: action.payload.body ? action.payload.body : {}
      }
    case t.VERIFY_EMAIL:
      return {
        ...state,
        verifyEmailData: action.payload ? action.payload : {}
      }

    case t.VERIFY_OTP:
      return {
        ...state,
        verifyOtpData: action.payload ? action.payload : {}
      }
    case t.CHANGE_PSWD:
      return {
        ...state,
        changePswdData: action.payload ? action.payload : {}
      }

    case t.FETCHED_CMS_PAGE_SINGLE:
      return {
        ...state,
        cmsPageSingle: action.payload ? action.payload : {},
      };

    case t.FETCHED_CMS_PAGE:
      return {
        ...state,
        cmsPage: action.payload.content.length > 0 ? action.payload.content : [],
      };

    case t.GET_SUB_MERCHANT:
      return {
        ...state,
        subMerchant: action.payload.body,
      };
      break;
    case t.GET_DOCUMENT_LIBRARY:

      return {
        ...state,
        documentList: action.payload.body.content
      }
      break;
    case t.FETCHED_DOCUMENT_MONITORING_FILE:
      return {
        ...state,
        documentViewFile: action.payload
      }
      break;
    case t.VERIFY_LOGIN_OTP:
      return {
        ...state,
        userDetails: action.payload
      }

    case t.GET_NOTIFICATIONS_PAGINATION:
      return {
        ...state,
        notificationList: action.payload.body?.content,
        totalElement: action.payload.body?.totalElements
      }

    default:
      return state;
  }
};
