import { combineReducers } from "redux";
import isLoggedReducer from "./isLoggedReducer";
import merchantListReducer from "./merchantListReducer";
import InvoiceReducer from "./InvoiceReducer";
import filterReducer from "./filterReducer";

const rootReducers = combineReducers({
  isLogged: isLoggedReducer,
  MerchantListReducer: merchantListReducer,
  InvoiceReducer: InvoiceReducer,
  FilterReducer: filterReducer
});
export default rootReducers;
