
import moment from 'moment';

export default class DateFormatter {
	dateformatter = (x) => {
		return moment(new Date(x)).format("DD MMM 'YY");
	}
	dateformatterYear = (x) => {
		return moment(new Date(x)).format("DD MMM YY");
	}
	dateformatterNumeric = (x) => {
		return moment(new Date(x)).format("MM/DD/YY");
	}
	dateformatterDM = (x) => {
		return moment(new Date(x)).format('DD MMM');
	}
	dateformatterFilter = (x) => {
		return moment(new Date(x)).format('YYYY-MM-DD');
	}
}