import t from "../actions/InvoicesActions/types";
import moment from "moment";
import DateFormatter from '../services/DateFormatter';

const initialState = {
    invoiceList: {},
    createData: {},
    netReceiable: [],
    netPayment: [],
    dateWiseInvoices: {},
    invoiceListUnderReview: {},
    pendingRequests: {},
    paymentDetails: {
        content: []
    },
    invoice: {},
    createRequestEcommerce: {},
    typeDescription: '',
    markInvoiceVerify: null,
    verifyInvoiceOtp: null,
    markInvoiceReject: null,
    requestData: [],
    requestRecoveries: [],
    pendingRecoveries: [],
    getPaymentDetailsById: {
        "pendings": [],
        "requests": [],
        "reserves": []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case t.FETCHED_MERCHANT_BUYER_INVOICES:
            if (action.payload.body) {
                var finterData = action.payload.body.content.filter((element) => {
                    return element.type == "Normal" && !element.tradRequestId;
                })
            }

            return {
                ...state,
                invoiceList: finterData
            };
        case t.CREATE_INVOICE:
            return {
                ...state,
                createData: action.payload.body
            }
        case t.GET_ALL_TRADITIONAL_REQUEST:
            //console.log("GET_INVOICES", action.payload);
            // let underReview = action.payload.body.filter((element) => {
            //     return element.status == "Under Review"
            // })
            return {
                ...state,
                invoiceListUnderReview: action.payload.body,
                typeDescription: action.payload.body ? action.payload.body.buyerPlatforms.typeDescription : ''

            };
        case t.GET_PENDING_REQUESTS:
            //console.log("GET_INVOICES", action.payload);
            // let underReview = action.payload.body.filter((element) => {
            //     return element.status == "Under Review"
            // })
            return {
                ...state,
                pendingRequests: action.payload.body
            };
        case t.GET_ALL_INVOICES:
            // Net Receviable

            //console.log("action.payload.body.Invoices", action.payload.body);

            const filterInvoices = action.payload.body ? action.payload.body.Invoices.filter((element) => {
                return element.type == "Normal" && element.invoiceStatus != "Under Review" &&
                    moment(new Date(element.dueDate)).isAfter(new Date(), 'd')
                    && (element.actualPaidAmount == undefined || element.actualPaidAmount == 0);
            }) : []


            //Net Payments
            const df = new DateFormatter();
            let tmpData = {};
            filterInvoices.forEach((element) => {
                (tmpData[df.dateformatter(element.dueDate)] = tmpData[df.dateformatter(element.dueDate)] || []).push(element);
            });


            let tmpDates = Object.keys(tmpData);
            tmpDates.sort(function (a, b) {
                return moment(a, 'DD MMM YY').format('X') - moment(b, 'DD MMM YY').format('X');
            })

            return {
                ...state,
                netReceiable: filterInvoices,
                netPayment: tmpDates,
                dateWiseInvoices: tmpData,
            };

        case t.GET_PAYMENT_DETAILS:
            console.log('action.payload.body.payments ', action.payload.body);
            return {
                ...state,
                paymentDetails: action.payload.body
            }

        case t.GET_INVOICE_DETAILS:
            return {
                ...state,
                invoice: action.payload.body.Invoices
            }

        case t.GET_CREATEREQUESTECOMMERCE:
            return {
                ...state,
                createRequestEcommerce: action.payload.body
            }
        case t.MARK_INVOICE_VERIFY:
            return {
                ...state,
                markInvoiceVerify: action.payload
            }
        case t.MARK_INVOICE_REJECT:
            return {
                ...state,
                markInvoiceReject: action.payload
            }
        case t.VERIFY_INVOICE_OTP:
            return {
                ...state,
                verifyInvoiceOtp: action.payload
            }

        case t.GET_INVOICE_VERIFY_STATUS:
            return {
                ...state,
                verifyInvoiceMessage: action.payload
            }
        case t.GET_TRADITIONAL_DATA:
            return {
                ...state,
                requestData: action.payload.traditionalrequests.content
            }
        case t.GET_REQUEST_RECOVERIES_DATA:
            return {
                ...state,
                requestRecoveries: action.payload.content
            }
        case t.GET_PENDING_RECOVERIES_DATA:
            return {
                ...state,
                pendingRecoveries: action.payload.content
            }
        case t.GET_PAYMENTS_DETAILS_BY_ID:
            console.log(action.payload);
            return {
                ...state,
                getPaymentDetailsById: action.payload
            }

        default:
            return state;
    }
};
