export default {
    FETCHED_ACTIVITY: 'FETCHED_ACTIVITY',
    FETCHED_DASHBOARD_ACTIVITY: "FETCHED_DASHBOARD_ACTIVITY",
    CREATE_REQUEST: "CREATE_REQUEST",
    GET_REMITTANCE: "GET_REMITTANCE",
    GET_REMITTANCE_BY_MONTH: "GET_REMITTANCE_BY_MONTH",
    GET_NOTIFICATIONS: "GET_NOTIFICATIONS",
    UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
    VERIFY_EMAIL: "VERIFY_EMAIL",
    VERIFY_OTP: "VERIFY_OTP",
    CHANGE_PSWD: "CHANGE_PSWD",
    FETCHED_CMS_PAGE: 'FETCHED_CMS_PAGE',
    FETCHED_CMS_PAGE_SINGLE: 'FETCHED_CMS_PAGE_SINGLE',
    FETCHED_CMS_PAGE_ERROR: 'FETCHED_CMS_PAGE_ERROR',
    FETCHED_CMS_PAGE_LOADING: 'FETCHED_CMS_PAGE_LOADING',
    CMS_PAGE_ENTRY: "CMS_PAGE_ENTRY",
    CMS_PAGE_ENTRY_ERROR: "CMS_PAGE_ENTRY_ERROR",
    CMS_PAGE_ENTRY_LOADING: "CMS_PAGE_ENTRY_LOADING",
    ADD_UPDATE_SUB_MERCHANT: "ADD_UPDATE_SUB_MERCHANT",
    GET_SUB_MERCHANT: "GET_SUB_MERCHANT",
    GET_DOCUMENT_LIBRARY: "GET_DOCUMENT_LIBRARY",
    FETCHED_DOCUMENT_MONITORING_FILE: "FETCHED_DOCUMENT_MONITORING_FILE",
    VERIFY_LOGIN_OTP: "VERIFY_LOGIN_OTP",
    LOGIN_WITH_OTP: "LOGIN_WITH_OTP",
    GET_NOTIFICATIONS_PAGINATION: "GET_NOTIFICATIONS_PAGINATION"

}